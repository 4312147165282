import { ClientEntity, EntityId, SettingsEntity } from "@jackfruit/common"
import { call, put, select, take } from "@redux-saga/core/effects"
import { nanoid } from "@reduxjs/toolkit"
import CryptoJS from "crypto-js"
import { END, SagaIterator, eventChannel } from "redux-saga"
import { ImageEntity } from "~/interfaces/entities/Image"
import { UploadEntity } from "~/interfaces/entities/Upload"
import { Files, ImageDimensions } from "~/services/Files"
import { PublicBucketConfig, S3Result, S3Service } from "~/services/S3Service"
import { getDefaultImageTransformation } from "~/services/Utils"
import { UploadFilePayload } from "../process"
import { clientsSelector } from "../state/client"
import { imageTransformations } from "../state/imageTransformations"
import { images, imagesSelectors } from "../state/images"
import { uploads, uploadsSelectors } from "../state/uploads"
import { RootState } from "../store"
import { getApi } from "./api"

// progress refresh delay in ms to prevent spamming the redux store
export function* processUploadToS3(payload: UploadFilePayload): SagaIterator {
  const { id, file } = payload

  const md5 = yield call(Files.generateKey, file)
  // update the hash on the entity
  yield put(
    uploads.actions.updateOne({
      id,
      changes: {
        hash: md5.toString(),
      },
    })
  )

  const settings: SettingsEntity = yield select(
    (state: RootState) => state.settings.data
  )
  // upload to s3
  const clientEntities: ClientEntity[] = yield select((state: RootState) =>
    clientsSelector.selectAll(state)
  )

  const clientEntity = clientEntities[0]

  yield call(
    processUploadChannel,
    id,
    md5.toString(),
    md5.toString(CryptoJS.enc.Base64),
    file,
    {
      bucket: clientEntity.uploadBucket,
      deviceId: settings.deviceToken,
    }
  )
}

function* processUploadChannel(
  id: EntityId,
  hash: string,
  hash64: string,
  file: File,
  s3Config: PublicBucketConfig
): SagaIterator {
  // create upload channel and run it
  const channel = yield call(uploadChannel, id, hash, hash64, file, s3Config)

  while (true) {
    const action = yield take(channel)
    yield put(action)
  }
}

// see https://blog.bam.tech/developer-news/handling-download-progress-with-redux-saga
const uploadChannel = (
  id: EntityId,
  hash: string,
  hash64: string,
  file: File,
  s3config: PublicBucketConfig
) => {
  const s3UploadService = new S3Service()

  return eventChannel(emitter => {
    const onSuccess = (id: EntityId, data: S3Result) => {
      emitter(
        uploads.actions.updateOne({
          id,
          changes: {
            bucket: data.bucket,
            key: data.key,
            location: data.location,
            progress: 100,
          },
        })
      )
      emitter(END)
    }
    const onError = (id: EntityId, error: Error) => {
      emitter(
        uploads.actions.updateOne({
          id,
          changes: {
            hasError: true,
            error: error.message,
          },
        })
      )
      emitter(error)
    }

    s3UploadService.upload(id, hash, hash64, file, s3config, onSuccess, onError)

    return () => {}
  })
}

export function* compressImage(file: File): SagaIterator<File> {
  const settings = yield select((state: RootState) => state.settings.data)
  const {
    imageCompressionIsEnabled,
    imageCompressionRate,
    imageCompressionSizeThreashold,
  } = settings

  const isSvg = file.type === "image/svg+xml"

  // 1 mb = 1000000 bytes
  const imageCompressionSizeThreasholdInBytes =
    imageCompressionSizeThreashold * 1000000

  const shouldCompressImage =
    imageCompressionIsEnabled &&
    !isSvg &&
    file.size > imageCompressionSizeThreasholdInBytes

  let compressedImage = file
  if (shouldCompressImage) {
    compressedImage = yield call(
      Files.compressImage,
      file,
      imageCompressionRate
    )
  }

  return compressedImage
}

export function* resizeImage(file: File): SagaIterator<File> {
  const isSvg = file.type === "image/svg+xml"
  if (isSvg) {
    return file
  }
  const resizedImage = yield call(Files.resizeImage, file)

  return resizedImage
}

export function* registerImageToAutopilot(payload: {
  id: EntityId
}): SagaIterator {
  const { id } = payload

  const upload: UploadEntity = yield select((state: RootState) =>
    uploadsSelectors.selectById(state, id)
  )
  const image: ImageEntity = yield select((state: RootState) =>
    imagesSelectors.selectById(state, upload.imageId)
  )

  const printicularApi = yield call(getApi)

  const { id: remoteId, ...printicularImage } = yield call(
    [printicularApi, "registerImage"],
    upload
  )

  const imageUpdated = {
    ...image,
    remoteId,
    ...printicularImage,
  }

  yield put(
    images.actions.updateOne({
      id: upload.imageId,
      changes: {
        ...imageUpdated,
      },
    })
  )
}

export function* createUpload(payload: {
  file: File
}): SagaIterator<{ uploadId: EntityId; imageId: EntityId }> {
  const { file } = payload
  const { addOne: addOneUpload } = uploads.actions
  const uploadId = nanoid()

  const imageId: EntityId = yield call(createImage, { file })

  yield put(
    addOneUpload({
      id: uploadId,
      name: file.name,
      type: file.type,
      size: file.size,
      imageId: imageId,
      status: "queued",
      hash: "",
      progress: 0,
      hasFailed: false,
      error: null,
    })
  )

  return { uploadId, imageId }
}

export function* createImage(payload: { file: File }): SagaIterator<EntityId> {
  const imageId = nanoid()
  const { file } = payload
  const isSvg = file.type === "image/svg+xml"

  let imageDimensions = { width: 0, height: 0 }
  if (!isSvg) {
    imageDimensions = yield call(Files.getImageDimensions, file)
  }

  const imageData = {
    ...imageDimensions,
  }

  yield put(images.actions.addOne({ id: imageId, ...imageData }))

  return imageId
}

export function* setImageTransformation(payload: {
  imageId: EntityId
  window: ImageDimensions
}): SagaIterator {
  const { addOne: addOneImageTransformation } = imageTransformations.actions
  const { updateOne: updateOneImage } = images.actions

  const { imageId, window } = payload

  const image: ImageEntity = yield select((state: RootState) =>
    imagesSelectors.selectById(state, imageId)
  )

  const { width, height } = image

  const defaultImageTransformation = getDefaultImageTransformation(
    {
      width: window.width,
      height: window.height,
    },
    {
      width,
      height,
    }
  )

  const imageTransformId = nanoid()

  yield put(
    addOneImageTransformation({
      id: imageTransformId,
      rotation: 0,
      translation: {
        x: defaultImageTransformation.x,
        y: defaultImageTransformation.y,
      },
      zoom: defaultImageTransformation.zoom,
      minZoom: defaultImageTransformation.zoom,
      dirty: false,
    })
  )

  yield put(
    updateOneImage({
      id: imageId,
      changes: {
        imageTransformId,
      },
    })
  )
}

export function* getImage(imageId: EntityId): SagaIterator<ImageEntity> {
  const image: ImageEntity = yield select((state: RootState) =>
    imagesSelectors.selectById(state, imageId)
  )

  return image
}
