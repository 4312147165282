import {
  FulfillmentTypes, PrinticularOrder,
  PrintServiceEntityHydrated,
  PrintServiceProductEntityHydrated,
  SettingsEntityHydrated
} from "@jackfruit/common"
import { Address, AddressPayload } from "~/interfaces/entities/Address"
import { couponCodeStatus } from "~/interfaces/entities/Cart"
import { GiftCertificateEntity } from "~/interfaces/entities/GiftCertificate"
import { ImageEntity } from "~/interfaces/entities/Image"
import { ImageRegionEntity } from "~/interfaces/entities/ImageRegion"
import { ImageTransformationEntity } from "~/interfaces/entities/ImageTransformation"
import { LineItemEntity } from "~/interfaces/entities/LineItem"
import { Payment } from "~/interfaces/entities/Payment"
import { ProductPageEntity } from "~/interfaces/entities/ProductPage"
import { StoreEntity } from "~/interfaces/entities/Store"
import { UploadEntity } from "~/interfaces/entities/Upload"
import { User } from "~/interfaces/entities/User"
import { ApplicationState } from "~/redux/state/application"
import { convertToEntity, splitFullName } from "./Utils"

export interface OrderSerializerData {
  appUrl: string
  deviceToken: string
  referralCode: string
  application: ApplicationState
  currency: string
  settings: SettingsEntityHydrated
  fulfillment: FulfillmentTypes
  giftCertificates: GiftCertificateEntity[]
  store: StoreEntity
  payment: Payment
  user: User
  address: Address
  lineItems: LineItemEntity[]
  productPages: ProductPageEntity[]
  imageRegions: ImageRegionEntity[]
  images: ImageEntity[]
  imageTransformations: ImageTransformationEntity[]
  uploads: UploadEntity[]
  printService: PrintServiceEntityHydrated
  printServiceProducts: PrintServiceProductEntityHydrated[]
  isFree: boolean
  couponCode: string
  couponCodeStatus: couponCodeStatus
  nonce: string
}

interface AutopilotAddress extends AddressPayload {
  emailAddress?: string
  phoneNumber?: string
}

export class PrinticularSerializer {
  static serializeOrder(payload: OrderSerializerData) {
    const {
      appUrl,
      deviceToken,
      referralCode,
      application,
      fulfillment,
      store,
      user,
      payment,
      address,
      lineItems,
      uploads,
      images,
      currency,
      printService,
      printServiceProducts,
      couponCode,
      giftCertificates,
      nonce,
    } = payload

    const isGiftCertificate = lineItems.some(
      (lineItem: LineItemEntity) => lineItem.giftCertificateId !== undefined
    )
    const fullName = splitFullName(address.name ?? "")

    const addressForAutopilot: AutopilotAddress = {
      firstName: fullName.firstName,
      lastName: fullName.lastName,
      addressLine1: address.line1,
      addressLine2: address.line2,
      city: address.city,
      state: address.state,
      postCode: address.postcode,
      emailAddress: user.email,
      phoneNumber: user.phoneNumber,
    }

    const attributes: any = {
      appUrl,
      deviceToken,
      currency: currency,
      clientName: application.name,
      clientVersion: application.appVersion,
      payment: { type: payment.type },
      country: fulfillment === "pickup" ? store.countryCode : address.country,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      userAgent: navigator.userAgent,
      couponCode,
      nonce,
      ...addressForAutopilot,
    }

    const relationships: any = {
      printService: {
        data: {
          id: printService.remoteId,
          type: "PrintService",
        },
      },
      lineItems: {
        data: lineItems.map(lineItem => ({
          id: lineItem.id,
          type: "LineItem",
        })),
      },
    }

    if (Boolean(referralCode)) {
      attributes.referralCode = referralCode
    }

    if (fulfillment === "pickup") {
      relationships.store = {
        data: { id: store.id, type: "Store" },
      }
    }

    const data = {
      type: "Order",
      attributes,
      relationships,
    }

    const lineItemsForAutopilot = lineItems.map(lineItem => {
      const product = printServiceProducts.find(
        printServiceProduct => printServiceProduct.id === lineItem.productId
      )

      if (isGiftCertificate) {
        return {
          id: lineItem.id,
          type: "LineItem",
          attributes: {
            quantity: lineItem.quantity,
            orientation: lineItem.orientation,
            giftCertificate: {
              toEmailAddress: giftCertificates[0].email,
              toName: giftCertificates[0].name,
              message: giftCertificates[0].message,
            },
          },
          relationships: {
            product: {
              data: {
                id: product?.remoteId.toString(),
                type: "Product",
              },
            },
          },
        }
      } else {
        const imageUrls = lineItem.renderedUploadIds.map(uploadId => {
          const upload = uploads.find(upload => upload.id === uploadId)
          const image = images.find(image => image.id === upload?.imageId)

          return {
            url: image?.externalUrl,
          }
        })

        return {
          id: lineItem.id,
          type: "LineItem",
          attributes: {
            quantity: lineItem.quantity,
            orientation: lineItem.orientation,
            images: { original: imageUrls },
          },
          relationships: {
            product: {
              data: {
                id: product?.remoteId.toString(),
                type: "Product",
              },
            },
          },
        }
      }
    })

    const order: any = {
      data,
      included: lineItemsForAutopilot,
    }

    return order
  }

  static deserializeOrder(printicularOrder: any) {
    const orderEntity = convertToEntity<any>(printicularOrder.data)
    const deserializedOrder: PrinticularOrder = {
      ...orderEntity,
    } as PrinticularOrder

    const lineItems = printicularOrder.included.filter(
      (included: any) => included.type === "LineItem"
    )

    const deserializedLineItems = lineItems.map((lineItem: any) => {
      const productId = lineItem.relationships.product.data.id
      const product = printicularOrder.included.find(
        (included: any) =>
          included.type === "Product" && included.id === productId
      )

      const entities = convertToEntity<any>(lineItem)

      if (product) {
        const deserializedProduct = convertToEntity(product)
        entities.product = deserializedProduct
      }

      return entities
    })
    deserializedOrder.lineItems = deserializedLineItems

    return deserializedOrder
  }
}
