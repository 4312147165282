import {
  BlockEntityHydrated,
  Entity,
  NavigationEntityHydrated,
  NavigationLinkEntityHydrated,
  OrientationType,
  PageEntity,
  PageEntityHydrated,
  PrinticularCategoryEntity,
  PrintServiceProductEntity,
  SettingsEntity,
} from "@jackfruit/common"
import arrayToTree from "array-to-tree"
import chroma from "chroma-js"
import currency from "currency.js"
import { format as fnsFormat } from "date-fns"
import { enUS, es, frCA, ja } from "date-fns/locale"
import { t } from "i18next"
import {
  camelCase,
  concat,
  drop,
  findIndex,
  isArray,
  join,
  split,
  trim,
  uniq,
} from "lodash"
import { UseFormUnregister } from "react-hook-form"
import { scroller } from "react-scroll"
import { findBestMatch } from "string-similarity"
import {
  CountryPhoneNumberConfiguration,
  PhoneNumberFormat,
} from "~/data/countryPhoneNumberConfiguration"
import { ViewBox } from "~/interfaces/editors/ViewBox"
import { LineItem } from "~/interfaces/entities/autopilot/AccountWithOrders"
import { ImageEntity } from "~/interfaces/entities/Image"
import { PageFlow } from "~/interfaces/entities/PageSession"
import { Coordinates } from "~/templates/layout/stores/StoresMap"
import currencies from "../data/currencies.json"
// @ts-ignore this file is generated at build time and does not exists before first build
import stateWithCountryCodes from "../data/isoStates.json"
import { Point, Size } from "./cropHelpers"

export const isBrowser = typeof window !== "undefined"

export interface JsonApiResponse<D = JsonApiEntity> {
  data: D
  included?: JsonApiEntity[]
}

export interface JsonApiResponseArray
  extends JsonApiResponse<JsonApiEntity[]> {}

export interface JsonApiEntity {
  id: string
  type: string
  attributes: any
  relationships?: any
}

//if id is a number but in string type(i.e. id:"321"), then convert it to number
//otherwise, leave it as is (some ids can be normal strings like id: "light-blue")
export const parseId = (id: string | number) => {
  return isNaN(Number(id)) ? id : Number(id)
}

export const convertToEntity = <T>(data: JsonApiEntity): T => {
  let entity: any = {
    id: parseId(data.id),
  }

  for (let attr in data.attributes) {
    entity[camelCase(attr)] = data.attributes[attr]
  }

  // Parse relationships
  if (data.relationships) {
    for (let related in data.relationships) {
      const relatedData = data.relationships[related].data

      const relatedIds = isArray(relatedData)
        ? relatedData.map((r: Entity) => parseId(r.id))
        : parseId(relatedData.id)

      const relatedKey = camelCase(related)
      if (!entity[relatedKey]) {
        entity[relatedKey] = relatedIds
      }
    }
  }

  return entity as T
}

export const denormalize = (data: any, included: any[]) => {
  const entity: any = {
    id: parseId(data.id),
  }

  for (let attr in data.relationships) {
    let relations = data.relationships[attr]
    let dataAsArray = relations.data
    if (!isArray(relations.data)) {
      dataAsArray = [relations.data]
    }

    const relatedEntities = dataAsArray.map((relation: any) => {
      const foundEntity = included.find(inc => {
        return (
          parseId(inc.id) === parseId(relation.id) && inc.type === relation.type
        )
      })

      return denormalize(foundEntity, included)
    })

    entity[camelCase(attr)] = relatedEntities
  }

  for (let attr in data.attributes) {
    entity[camelCase(attr)] = data.attributes[attr]
  }

  return entity
}

export const convertToEntities = <T>(response: any): T => {
  const { data, included } = response

  const entity = denormalize(data, included)

  return entity as T
}

export const generatePathForPage = (
  page: PageEntity | PageEntityHydrated,
  pages: PageEntityHydrated[]
): string => {
  let node = page
  const slugs = []
  slugs.push(node.slug)
  while (node.parentId !== null) {
    // eslint-disable-next-line no-loop-func
    node = pages.find(p => p.id === node.parentId)!
    slugs.push(node.slug)
  }

  // force trailing slashes on generated urls
  let fullPath = slugs.reverse().join("/")
  if (fullPath.length && fullPath.charAt(fullPath.length - 1) !== "/") {
    fullPath += "/"
  }

  return fullPath
}

export const addPagePathToNavigationLink = (
  links: NavigationLinkEntityHydrated[],
  pages: PageEntityHydrated[]
) => {
  return links
    .sort((a, b) => (a.order < b.order ? -1 : 1))
    .map(link => {
      if (link.type === "page") {
        const page = pages.find(p => p.id === link.pageId)!
        const pagePath = generatePathForPage(page, pages)
        return {
          ...link,
          path: pagePath,
          page: {
            ...page,
            path: pagePath,
          },
        }
      } else {
        return link
      }
    })
}

export const getDefaultImageTransformation = (
  targetDimensions: Size,
  imageDimensions: Size,
  rotation: number = 0
) => {
  const shouldInverseDimensions = Math.abs(rotation % 180) === 90

  const correctedImageDimensions = {
    width: imageDimensions.width,
    height: imageDimensions.height,
  }

  if (shouldInverseDimensions) {
    correctedImageDimensions.width = imageDimensions.height
    correctedImageDimensions.height = imageDimensions.width
  }

  const imageRatio =
    correctedImageDimensions.width / correctedImageDimensions.height
  const productRatio = targetDimensions.width / targetDimensions.height

  let x = 0
  let y = 0
  let zoom = 10

  if (imageRatio > productRatio) {
    // image width > product width (in proportions)
    // fit to product height
    // calculate the zoom required to fit the product height
    zoom = targetDimensions.height / correctedImageDimensions.height
  } else {
    // image width < product width (in proportions)
    // fit to product width
    // calculate the zoom required to fit the product width
    zoom = targetDimensions.width / correctedImageDimensions.width
  }

  y = (targetDimensions.height - imageDimensions.height) / 2
  x = (targetDimensions.width - imageDimensions.width) / 2

  return {
    x,
    y,
    zoom,
  }
}

export const getDefaultViewBox = (
  productDimensions: Size,
  imageDimensions: Size
): ViewBox => {
  const imageRatio = imageDimensions.width / imageDimensions.height
  const productRatio = productDimensions.width / productDimensions.height

  let x = 0
  let y = 0
  let width = 0
  let height = 0

  if (imageRatio > productRatio) {
    // image width > product width (in proportions)
    // fit to product height
    width = Math.floor(imageDimensions.height * productRatio)
    height = imageDimensions.height
    x = Math.ceil((imageDimensions.width - width) * 0.5)
    y = 0
  } else {
    // image width < product width (in proportions)
    // fit to product width
    width = imageDimensions.width
    height = Math.ceil(imageDimensions.width / productRatio)
    x = 0
    y = Math.ceil((imageDimensions.height - height) * 0.5)
  }

  return {
    x,
    y,
    width,
    height,
  }
}

export const formatCurrency = (currencyCode: string, value: number) => {
  const currencyConfig = currencies.find(cu => cu.code === currencyCode)
  const options = currencyConfig
    ? {
        symbol: currencyConfig.symbolNative,
      }
    : {}

  return currency(value, options).format()
}

export const formatDate = (date: Date, format: string, language: string) => {
  return fnsFormat(date, format, { locale: getLocaleByLanguage(language) })
}

export const getLocaleByLanguage = (language: string) => {
  switch (language) {
    case "en-US":
      return enUS
    case "fr-CA":
      return frCA
    case "ja-JP":
      return ja
    case "es-US":
      return es
    default:
      return enUS
  }
}

export const getPageCountryCode = (
  pickupTerritories: string[],
  deliveryTerritories: string[]
): string => {
  const allTerritories = uniq(concat(pickupTerritories, deliveryTerritories))

  return allTerritories.join(",")
}

export const loopValue = (value: number, min: number, max: number) => {
  if (value < min) {
    return max
  }

  if (value > max) {
    return min
  }

  return value
}

export const listOfStatesByCountryCode = (countryCode: string) => {
  if (!countryCode) {
    return []
  }

  const stateWithCountryCode = stateWithCountryCodes.find(
    country => country.iso2 === countryCode
  )

  if (stateWithCountryCode) {
    return stateWithCountryCode.states
  }

  return []
}

export const getCountryName = (countryCode: string) => {
  const found = stateWithCountryCodes.find(
    country => country.iso2 === countryCode
  )

  if (found) {
    return found.name
  }

  return countryCode
}

export const findPhoneNumberConfiguration = (
  configuration: CountryPhoneNumberConfiguration,
  countryCode: string
) => {
  if (configuration[countryCode] === undefined) {
    return configuration.US
  }
  return configuration[countryCode]
}

export const formatPhoneNumber = (nextValue: string, mask: string) => {
  const numberWithoutSeparators = nextValue.replace(/\D/g, "")
  const maskAsArray = mask.split("")
  const numberAsArray = numberWithoutSeparators.split("")
  const result: string[] = []

  let index = 0
  maskAsArray.forEach((char: string) => {
    if (numberAsArray[index] === undefined) {
      return
    }
    if (char === "#") {
      result.push(numberAsArray[index])
      index++
    } else {
      result.push(char)
    }
  })

  if (index < numberAsArray.length) {
    const rest = numberAsArray.splice(index, numberAsArray.length)
    result.push(rest.join(""))
  }

  const editedResult = result.filter(char => char !== undefined)

  return {
    formatValue: editedResult.join(""),
  }
}

export const findPhoneNumberFormat = (
  numberLength: number,
  formats: PhoneNumberFormat[]
) => {
  const min = formats[0].numberLength
  const max = formats[formats.length - 1].numberLength

  if (numberLength < min) {
    return formats[0]
  }

  if (numberLength > max) {
    return formats[formats.length - 1]
  }

  const newFormat = formats.find(format => format.numberLength >= numberLength)

  return newFormat
}

export const floatsAreEqual = (
  f1: number,
  f2: number,
  threshold: number = 0.0065
) => {
  return Math.abs(f1 - f2) < threshold
}

export const getRatioDecimal = (width: number, height: number): number => {
  return width < height ? width / height : height / width
}

export const getProductRatio = (
  product: any
): { productRatio1: number; productRatio2: number } => {
  const { pixelWidth, pixelHeight, categoryName, metaData } = product
  //Canvas products have a wrap
  //When matching aspect ratios, the webapp includes the wrap size (2x on each side)
  //for the total width and height of canvas products
  const isCanvas = categoryName === "canvas"
  const wrap = metaData?.wrap || 0

  const width = isCanvas ? pixelWidth + wrap * 2 : pixelWidth
  const height = isCanvas ? pixelHeight + wrap * 2 : pixelHeight

  const ratioOverride = metaData?.ratioOverride
  if (ratioOverride) {
    const [overridenWidth, overridenHeight] = ratioOverride.split(":")

    return {
      productRatio1: overridenWidth / overridenHeight,
      productRatio2: overridenHeight / overridenWidth,
    }
  }

  return { productRatio1: width / height, productRatio2: height / width }
}

export const unregisterFields = (
  unregister: UseFormUnregister<any>,
  fields: string[]
) => {
  fields.forEach(field => {
    unregister(field, {
      keepValue: true,
      keepDirty: true,
      keepTouched: true,
    })
  })
}

export const getCurrentPosition = async (): Promise<GeolocationPosition> => {
  return new Promise((resolve, reject) => {
    const success: PositionCallback = position => {
      resolve(position)
    }
    const error: PositionErrorCallback = () => {
      reject("Unable to retrieve your location")
    }

    if (!navigator.geolocation) {
      reject("Geolocation is not supported by your browser")
    } else {
      navigator.geolocation.getCurrentPosition(success, error)
    }
  })
}

export const generateDeviceToken = () => {
  return [
    Math.random().toString(36).substr(2, 9),
    Math.random().toString(36).substr(2, 9),
    Math.random().toString(36).substr(2, 9),
    Math.random().toString(36).substr(2, 9),
  ].join("-")
}

export const getProductArea = (product: PrintServiceProductEntity): number => {
  const { pixelHeight, pixelWidth } = product
  return pixelHeight * pixelWidth
}

export const filterByClosestProductArea = (
  products: PrintServiceProductEntity[],
  goal: number
): PrintServiceProductEntity[] => {
  if (products.length === 1) {
    return products
  }

  const productsByClosetArea = products.sort((productA, productB) => {
    const productAreaA = getProductArea(productA)
    const productAreaB = getProductArea(productB)

    return Math.abs(productAreaA - goal) - Math.abs(productAreaB - goal)
  })

  const closetProductArea = getProductArea(productsByClosetArea[0])
  return productsByClosetArea.filter(
    p => getProductArea(p) === closetProductArea
  )
}

export const filterBySameProductAspectRatio = (
  products: PrintServiceProductEntity[],
  targetRatio: number,
  tolerrance: number
): PrintServiceProductEntity[] => {
  return products.filter(p =>
    floatsAreEqual(targetRatio, getRatioDecimal(p.width, p.height), tolerrance)
  )
}

export const getProductWithMostSimilarShortDescription = (
  products: PrintServiceProductEntity[],
  targetShortDescription: string
): PrintServiceProductEntity => {
  const potentialDescriptions = products.map(
    product => product.shortDescription
  )

  const results = findBestMatch(targetShortDescription, potentialDescriptions)
  const bestMatchingDescription = results.bestMatch.target

  const bestMatchingProduct = products.find(
    product => product.shortDescription === bestMatchingDescription
  )

  return bestMatchingProduct!
}

// A product is considered to be comptabile if it's in the same category and requires the same amount of images
export const getCompatibleProducts = (
  lineItemProduct: PrintServiceProductEntity,
  availableProducts: PrintServiceProductEntity[]
): PrintServiceProductEntity[] => {
  const { categoryName, minImages, maxImages } = lineItemProduct

  return availableProducts.filter(
    aP =>
      aP.categoryName === categoryName &&
      aP.minImages === minImages &&
      aP.maxImages === maxImages
  )
}

export const getMostCompatibleProduct = (
  product: PrintServiceProductEntity,
  compatibleProducts: PrintServiceProductEntity[]
): PrintServiceProductEntity | null => {
  if (compatibleProducts.length === 0) {
    return null
  }

  const sameProduct = compatibleProducts.find(cP => cP.id === product.id)
  if (sameProduct) {
    return sameProduct
  }

  const sameProductCode = compatibleProducts.find(
    cP => cP.productCode === product.productCode
  )
  if (sameProductCode) {
    return sameProductCode
  }

  let mostCompatibleProducts
  const { width, height, pixelHeight, pixelWidth } = product

  // Compare product's aspect ratio with the original product with a 0.0065 tolerance
  const productRatio = getRatioDecimal(width, height)
  const compatibleRatioProducts = filterBySameProductAspectRatio(
    compatibleProducts,
    productRatio,
    0.0065
  )
  // apply filter only if there are compatible products with the same aspect ratio
  mostCompatibleProducts =
    compatibleRatioProducts.length > 0
      ? compatibleRatioProducts
      : compatibleProducts

  // Find the product(s) with closest product area to the original product
  const productArea = pixelHeight * pixelWidth
  const closestAreaProducts = filterByClosestProductArea(
    mostCompatibleProducts,
    productArea
  )

  if (closestAreaProducts.length === 1) {
    return closestAreaProducts[0]
  }
  mostCompatibleProducts = closestAreaProducts

  //Finall if there's still more than 1 product, compare short description
  return getProductWithMostSimilarShortDescription(
    mostCompatibleProducts,
    product.shortDescription
  )
}

const toRad = (value: number) => {
  return (value * Math.PI) / 180
}

export const kmToMiles = (distanceInKm: number) => {
  return distanceInKm * 0.621371192
}

export const calculateLatLngDistanceInKm = (
  lat1: number,
  lng1: number,
  lat2: number,
  lng2: number
) => {
  const r = 6371 // Radius of the earth in km
  const dLat = toRad(lat2 - lat1)
  const dLng = toRad(lng2 - lng1)
  lat1 = toRad(lat1)
  lat2 = toRad(lat2)

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLng / 2) * Math.sin(dLng / 2) * Math.cos(lat1) * Math.cos(lat2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = r * c
  return d
}

export const getHumanDistance = (distanceInKm: number, countryCode: string) => {
  if (countryCode === "US") {
    const distanceInMiles = kmToMiles(distanceInKm)
    switch (Math.round(distanceInMiles)) {
      case 0:
        return t("services.Utils.LessThanMile")
      case 1:
        return t("services.Utils.MileAway")
    }
    return Math.round(distanceInMiles) + t("services.Utils.MilesAway")
  }

  switch (Math.round(distanceInKm)) {
    case 0:
      return t("services.Utils.LessThanKm")
    case 1:
      return t("services.Utils.KmAway")
  }
  return Math.round(distanceInKm) + t("services.Utils.KmsAway")
}

/**
 * Returns the excess of a source array based on a target array
 * eg. getExcessArray([2,3,4,1], [1,2]) should returns [4,1]
 * return [] if there's no excess
 *
 * @param source inital array
 * @param target compared array
 */
export const getExcessArray = (source: any[], target: any[]): any[] => {
  const sourceLength = source.length
  const targetLength = target.length

  if (sourceLength <= targetLength) {
    return []
  }

  const diff = sourceLength - targetLength
  return source.slice(-diff)
}

export const getClampingValues = (
  regionDimensions: Size,
  imageDimensions: Size,
  zoom: number,
  rotation = 0
) => {
  const { width: containerWidth, height: containerHeight } = regionDimensions
  const { width: imageWidth, height: imageHeight } = imageDimensions

  const ratio = imageWidth / imageHeight
  const hasRotation = Math.abs((rotation / 90) % 2) === 1
  const rotationCorrection = hasRotation ? ratio : 1

  const maxX = (zoom / rotationCorrection - 1) * (imageWidth / 2)
  const minX = containerWidth - imageWidth - maxX

  const maxY = (zoom * rotationCorrection - 1) * (imageHeight / 2)
  const minY = containerHeight - imageHeight - maxY

  return {
    minX,
    maxX,
    minY,
    maxY,
  }
}

export const project = (projectionMatrix: any, point: Point) => {
  const { x, y } = point
  return {
    x: x * projectionMatrix.a + projectionMatrix.e,
    y: y * projectionMatrix.d + projectionMatrix.f,
  }
}

export const unproject = (projectionMatrix: any, point: Point) => {
  const { x, y } = point
  return {
    x: (x - projectionMatrix.e) / projectionMatrix.a,
    y: (y - projectionMatrix.f) / projectionMatrix.d,
  }
}

export const sortProductsByTemplateCategories = (
  products: PrintServiceProductEntity[],
  categories: PrinticularCategoryEntity[]
): PrintServiceProductEntity[] => {
  const categoriesNames = categories.map(c => c.name)

  let productsInsideCategories: PrintServiceProductEntity[] = []
  categoriesNames.forEach(name => {
    productsInsideCategories = [
      ...productsInsideCategories,
      ...products.filter(p => p.categoryName === name),
    ]
  })

  const productsOutsideCategories = products.filter(
    p => !categoriesNames.includes(p.categoryName)
  )

  return [...productsInsideCategories, ...productsOutsideCategories]
}

export const findDefaultProductForImage = (
  image: ImageEntity,
  products: PrintServiceProductEntity[]
) => {
  const imageRatio = getRatioDecimal(image.width, image.height)

  if (floatsAreEqual(imageRatio, 1.0)) {
    // square product, get the default square available or the first one in the list
    const defaultSquareProduct = products.find(
      product => product.isDefaultSquareProduct
    )
    if (defaultSquareProduct) {
      return defaultSquareProduct
    }
    return products.find(product => product.pixelWidth === product.pixelHeight)
  } else {
    // non square product, get the default rectangle available or the first one in  the list
    const defaultRectangleProduct = products.find(
      product => product.isDefaultRectangleProduct
    )
    if (defaultRectangleProduct) {
      return defaultRectangleProduct
    }
    return products.find(product => product.pixelWidth !== product.pixelHeight)
  }
}

export const getLocalStorageItem = (key: string, defaultValue?: any) => {
  try {
    const value = localStorage.getItem(key)

    if (value !== null) {
      try {
        return JSON.parse(value)
      } catch {
        return value
      }
    }
  } catch (error) {
    // If user is in private mode or has storage restriction
    return defaultValue
  }

  if (key) {
    defaultValue && localStorage.setItem(key, JSON.stringify(defaultValue))
  }
  return defaultValue
}

export const setLocalStorageItem = (key: string, value?: any) => {
  try {
    if (key) {
      localStorage.setItem(key, JSON.stringify(value))
    }
  } catch {
    // If user is in private mode or has storage restriction
  }
  return value
}

export const getPageFlow = (page: PageEntityHydrated) => {
  const cartBlockIndex = findIndex(
    page.blocks,
    (block: BlockEntityHydrated) => block.type === "cart"
  )
  const storeBlockIndex = findIndex(
    page.blocks,
    (block: BlockEntityHydrated) => block.type === "store-search"
  )

  let pageFlow: PageFlow = "product-first"
  if (storeBlockIndex !== -1 && storeBlockIndex < cartBlockIndex) {
    pageFlow = "store-first"
  }

  return pageFlow
}

export const getHigherDimension = (width: number, height: number) => {
  return width >= height ? width : height
}

export const getLowerDimension = (width: number, height: number) => {
  return width <= height ? width : height
}

export const getOrientationDimensions = (
  width: number,
  height: number,
  orientation: OrientationType
) => {
  const isLandscape = orientation === "landscape"
  const higherDimension = getHigherDimension(width, height)
  const lowerDimension = getLowerDimension(width, height)

  return {
    width: isLandscape ? higherDimension : lowerDimension,
    height: isLandscape ? lowerDimension : higherDimension,
  }
}

export const scrollTo = (to: string) => {
  scroller.scrollTo(to, {
    duration: 500,
    smooth: true,
  })
}

export const getGeoJSONFeatures = (points: Coordinates[]) => {
  const GeoJSON: any = []

  points.forEach(point => {
    GeoJSON.push({
      type: "Feature",
      properties: {
        id: point.id,
        cluster: false,
      },
      geometry: {
        type: "Point",
        coordinates: [point.longitude, point.latitude],
      },
    })
  })

  return GeoJSON
}

export const getGeoJSONCollection = (points: Coordinates[]) => {
  return {
    type: "FeatureCollection",
    features: getGeoJSONFeatures(points),
  }
}

const shouldShowProductImage = (lineItem: LineItem) => {
  const { productImages } = lineItem.product[0]
  if (!productImages) {
    return false
  }

  if (productImages.length === 0) {
    return false
  }

  const { metaData } = productImages[0]
  if (!metaData) {
    return false
  }

  const { thumbnail } = metaData

  return thumbnail
}

export const replaceProcessedImageWithProductImageIfNeeded = (
  lineItem: LineItem
) => {
  let newLineItem = lineItem

  if (!lineItem.processedImage) {
    if (shouldShowProductImage(lineItem)) {
      const { productImages } = lineItem.product[0]
      const firstProductImage = productImages[0]
      newLineItem = {
        ...lineItem,
        processedImage: [
          {
            height: 100,
            width: 100,
            bytesize: 0,
            checksum: firstProductImage.hash,
            filename: firstProductImage.hash,
            fullUrl: firstProductImage.url,
            id: firstProductImage.id,
            mimeType: firstProductImage.mime,
          },
        ],
      }
    } else {
      newLineItem = {
        ...lineItem,
        processedImage: [
          {
            height: 100,
            width: 100,
            bytesize: 0,
            checksum: "",
            filename: "",
            fullUrl: "",
            id: "0",
            mimeType: "",
          },
        ],
      }
    }
  }

  return newLineItem
}

export type PageTreeItem = arrayToTree.Tree<PageEntityHydrated>
export type NavigationTreeItem = arrayToTree.Tree<NavigationLinkEntityHydrated>

export const filterPagesWithoutOrderSuccess = (pages: PageEntityHydrated[]) => {
  return pages.filter(page =>
    page.blocks.every(
      block =>
        block.type !== "order-thank-you" && block.type !== "order-complete"
    )
  )
}

export function getSitemapTree(config: {
  settings: SettingsEntity
  navigations: NavigationEntityHydrated[]
  pages: PageEntityHydrated[]
}) {
  const {
    settings: { sitemapNavigationId },
    navigations,
    pages,
  } = config

  const filteredPages = filterPagesWithoutOrderSuccess(pages)

  let navigation: NavigationEntityHydrated | undefined
  let tree: PageTreeItem[] | NavigationTreeItem[]

  if (sitemapNavigationId) {
    navigation = navigations.find(({ id }) => +id === +sitemapNavigationId)
    tree = arrayToTree<NavigationLinkEntityHydrated>(navigation!.links, {
      parentProperty: "parentId",
    })
  } else {
    tree = arrayToTree<PageEntityHydrated>(
      filteredPages.sort(
        (thisPage: PageEntityHydrated, otherPage: PageEntityHydrated) =>
          thisPage.slug.localeCompare(otherPage.slug)
      ),
      { parentProperty: "parentId" }
    )
  }

  return {
    tree,
    navigation,
    sitemapNavigationId,
  }
}

export const alertUserBeforeLeaving = (e: BeforeUnloadEvent) => {
  e.preventDefault()
  e.returnValue = ""
}

export const splitFullName = (fullName: string) => {
  const nameParts = split(trim(fullName), " ")
  const firstName = nameParts[0]
  const lastName =
    nameParts.length > 1 ? join(drop(nameParts, 1), " ") : firstName

  return { firstName, lastName }
}

export const getFallbackTextColor = (color: string) => {
  const textColorFallback =
    chroma.contrast(color, "white") > 2.5 ? "white" : "black"

  return textColorFallback
}
