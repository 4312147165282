module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Metricks by Autopilot Print","displayName":"Metricks by Autopilot Print","short_name":"MetricksbyAutopilotPrint","crossOrigin":"use-credentials","start_url":"/","background_color":"#2AAAE1","theme_color":"#2AAAE1","display":"minimal-ui","icon":"static/images/cache/40aafc9305dfd9493cc5d70640e1c40f.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"6aaefdde530e1ed3844ed93d9635e9be"},
    },{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[],"excludePattern":"(excluded-link|external)"},
    },{
      plugin: require('../node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[],"resetCSS":true,"isUsingColorMode":true},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"release":"4.11.0-rc1-926","dsn":"https://4825e17abd8d434897c5061d1be577bb@o149892.ingest.sentry.io/5590009","environment":"staging"},
    },{
      plugin: require('../node_modules/gatsby-plugin-ts-config/gatsby-browser.js'),
      options: {"plugins":[],"configDir":"./gatsby-config/","babel":{"plugins":[["/app/builder-v2/webapp/node_modules/babel-plugin-module-resolver/lib/index.js",{"root":["/app/builder-v2/webapp"],"alias":{"~":"./src"}}]]}},
    }]
