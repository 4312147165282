import { BlockType } from "@jackfruit/common"
import React from "react"
import { Link } from "react-scroll"
import { useNextActionableBlock } from "~/hooks/useNextActionableBlock"

interface Props {
  currentBlock: BlockType
  toType: "nextAction" | "custom"
  to?: string
  offset: number
  isDisabled?: boolean
}

const ScrollLinkWrapper: React.FC<Props> = ({
  currentBlock,
  toType = "nextAction",
  to = null,
  offset,
  children,
  isDisabled = false,
}) => {
  // Page config is used to determine the first block
  const { nextBlockType } = useNextActionableBlock(currentBlock)

  if (isDisabled) {
    return <>{children}</>
  }

  return (
    <Link
      to={(toType === "nextAction" ? nextBlockType : to) ?? ""}
      offset={offset}
      spy={true}
      smooth={true}
      duration={500}
    >
      {children}
    </Link>
  )
}

export default ScrollLinkWrapper
