import {
  PrintServiceProductEntity,
  PrintServiceProductImageEntity,
} from "@jackfruit/common"
import { SagaIterator } from "@redux-saga/types"
import { nanoid, PayloadAction } from "@reduxjs/toolkit"
import {
  actionChannel,
  call,
  getContext,
  put,
  select,
  take,
  takeEvery,
} from "redux-saga/effects"
import { CartEntity } from "~/interfaces/entities/Cart"
import { LineItemEntity } from "~/interfaces/entities/LineItem"
import { PageSessionEntity } from "~/interfaces/entities/PageSession"
import { getPagesFromProduct } from "~/services/PrintServiceProductHelpers"
import {
  actions,
  CreateLineItemFromTemplatePayload,
  CreateLineItemPayload,
  RemoveLineItemPayload,
} from "../process"
import { carts, cartsSelector } from "../state/carts"
import { giftCertificates } from "../state/giftCertificates"
import { imageRegions } from "../state/imageRegions"
import { lineItems, lineItemsSelectors } from "../state/lineItems"
import { pageSessions } from "../state/pageSessions"
import { printServiceProductImagesSelector } from "../state/printServiceProductImages"
import { printServiceProductsSelector } from "../state/printServiceProducts"
import { productPages } from "../state/productPages"
import { textRegions } from "../state/textRegions"
import { uploads } from "../state/uploads"
import { RootState } from "../store"
import { addLineItemToCart, getCurrentCart } from "./cart"
import { getCurrentPageSession } from "./pageSession"
import { AnalyticAction, processAnalyticEvents } from "./processAnalyticEvents"
import { getProductTemplateVariantForProduct } from "./productTemplateVariants"
import { prepareProductPagesForVariant } from "./templateHelpers"

// ====================================================
// Create one line item with default configuration
// from selected product
// ====================================================
export function* watchCreateLineItem() {
  yield takeEvery(actions.createLineItem.type, processCreateLineItem)
}

export function* processCreateLineItem(
  action: PayloadAction<CreateLineItemPayload>
): SagaIterator<any> {
  const { productId } = action.payload

  const getCurrentPageId = yield getContext("getCurrentPageId")
  const currentPageId = getCurrentPageId()

  const cart: CartEntity = yield select((state: RootState) =>
    cartsSelector.selectById(state, currentPageId)
  )

  const printServiceProduct: PrintServiceProductEntity = yield select(
    (state: RootState) =>
      printServiceProductsSelector.selectById(state, productId)
  )

  const printServiceProductImages: PrintServiceProductImageEntity[] =
    yield select((state: RootState) =>
      printServiceProductImagesSelector.selectByIds(
        state,
        printServiceProduct.printServiceProductImages
      )
    )

  const { addOne: addOneImageRegion } = imageRegions.actions
  const { addOne: addOneTextRegion } = textRegions.actions
  const { addOne: addOneProductPage } = productPages.actions
  const { addOne: addOneLineItem } = lineItems.actions
  const { updateOne: updateCart } = carts.actions

  const requiredProductPages = getPagesFromProduct({
    ...printServiceProduct,
    printServiceProductImages,
  })

  for (let i = 0; i < requiredProductPages.length; i++) {
    const requiredProductPage = requiredProductPages[i]
    const imageRegionIds = []
    const textRegionIds = []
    for (let j = 0; j < requiredProductPage.imageRegions.length; j++) {
      const imageRegion = requiredProductPage.imageRegions[j]
      yield put(
        addOneImageRegion({
          id: imageRegion.id,
          window: imageRegion.window,
        })
      )
      imageRegionIds.push(imageRegion.id)
    }

    for (let j = 0; j < requiredProductPage.textRegions.length; j++) {
      const textRegion = requiredProductPage.textRegions[j]
      yield put(
        addOneTextRegion({
          id: textRegion.id,
          window: textRegion.window,
          align: textRegion.align,
          color: textRegion.color,
          font: textRegion.font,
          key: textRegion.key,
          placeholder: textRegion.placeholder,
          size: textRegion.size,
          defaultSize: textRegion.defaultSize,
          text: "",
        })
      )
      textRegionIds.push(textRegion.id)
    }

    yield put(
      addOneProductPage({
        id: requiredProductPage.id,
        width: requiredProductPage.width,
        height: requiredProductPage.height,
        imageRegionIds,
        textRegionIds,
      })
    )
  }

  const productPageIds = requiredProductPages.map(page => page.id)
  const lineItemId = nanoid()

  let orientation =
    printServiceProduct.metaData?.orientationOverride ?? "portrait"
  if (action.payload.createFromProduct) {
    orientation =
      printServiceProduct.width < printServiceProduct.height
        ? "portrait"
        : "landscape"
  }

  const giftCertificateId =
    printServiceProduct.categoryName === "gift certificate"
      ? nanoid()
      : undefined

  const giftCertificateProductId =
    printServiceProduct.categoryName === "gift certificate"
      ? printServiceProduct.id
      : undefined

  //Create gift certificate
  if (giftCertificateId && giftCertificateProductId) {
    yield put(
      giftCertificates.actions.addOne({
        id: giftCertificateId,
        productId: giftCertificateProductId,
        name: "",
        email: "",
        message: "",
      })
    )
  }

  // create line item
  yield put(
    addOneLineItem({
      id: lineItemId,
      productPageIds,
      productId,
      quantity: 1,
      orientation,
      isReadOnly: false,
      renderedUploadIds: [],
      isTemplate: false,
      giftCertificateId,

      // Open editor straight away if a product was selected instead of an image being uploaded
      isEditing: action.payload.createFromProduct ? true : false,
      isReady: action.payload.createFromProduct ? true : false,
      isConfirmed: action.payload.createFromProduct ? false : true,
    })
  )

  // add line item to the current page's cart
  yield put(
    updateCart({
      id: cart.id,
      changes: {
        lineItemIds: [...cart.lineItemIds, lineItemId],
      },
    })
  )

  yield call(
    processAnalyticEvents,
    AnalyticAction({
      eventType: "addOneItemToCart",
      data: { lineItemId },
    })
  )

  yield put(
    actions.updateOrderSummary({
      reason: "new line item created",
      reasonType: "cartChange",
    })
  )

  return lineItemId
}

// ====================================================
// create line item from a template
// ====================================================
export function* watchCreateLineItemFromTemplate() {
  yield takeEvery(
    actions.createLineItemFromTemplate.type,
    processCreateLineItemFromTemplate
  )
}

function* processCreateLineItemFromTemplate(
  action: PayloadAction<CreateLineItemFromTemplatePayload>
): SagaIterator<any> {
  const { productTemplateId, productId, blockTemplateId, blockId } =
    action.payload

  const getCurrentPageId = yield getContext("getCurrentPageId")
  const currentPageId = getCurrentPageId()

  const cart: CartEntity = yield select((state: RootState) =>
    cartsSelector.selectById(state, currentPageId)
  )

  const printServiceProduct: PrintServiceProductEntity = yield select(
    (state: RootState) =>
      printServiceProductsSelector.selectById(state, productId)
  )

  const variants = yield call(getProductTemplateVariantForProduct, {
    productId,
    productTemplateId,
  })
  const [firstVariant] = variants
  const [firstProductPage] = firstVariant.variant.pages
  const defaultOrientation =
    firstProductPage.width > firstProductPage.height ? "landscape" : "portrait"

  const orientation =
    printServiceProduct.metaData?.orientationOverride ?? defaultOrientation

  // create or remove product pages based on the template variant
  const productPageIds = yield call(prepareProductPagesForVariant, {
    productPageIds: [],
    variant: firstVariant.variant,
  })

  const { addOne: addOneLineItem } = lineItems.actions
  const { updateOne: updateCart } = carts.actions

  const lineItemId = nanoid()

  // Create line item
  yield put(
    addOneLineItem({
      id: lineItemId,
      productPageIds,
      productId,
      productTemplateId,
      blockTemplateId,
      blockId,
      productTemplateVariantId: firstVariant.variant.key,
      quantity: 1,
      orientation,
      isEditing: true,
      isTemplate: true,
      isConfirmed: false,
      isReadOnly: false,
      isReady: true,
      renderedUploadIds: [],
    })
  )

  // Add line item to the current page's cart
  yield put(
    updateCart({
      id: cart.id,
      changes: {
        lineItemIds: [...cart.lineItemIds, lineItemId],
      },
    })
  )

  yield call(
    processAnalyticEvents,
    AnalyticAction({
      eventType: "addOneItemToCart",
      data: { lineItemId },
    })
  )

  yield put(
    actions.updateOrderSummary({
      reason: "lineItem created from template",
      reasonType: "cartChange",
    })
  )

  return lineItemId
}

export function* watchRemoveLineItem() {
  yield takeEvery(actions.removeLineItem.type, processRemoveLineItem)
}

function* processRemoveLineItem(action: PayloadAction<RemoveLineItemPayload>) {
  const { lineItemId } = action.payload
  const cart: CartEntity = yield call(getCurrentCart)

  const newLineItemIds = cart.lineItemIds.filter(id => id !== lineItemId)

  const lineItem: LineItemEntity = yield select((state: RootState) =>
    lineItemsSelectors.selectById(state, lineItemId)
  )

  if (lineItem.giftCertificateId !== undefined) {
    yield put(giftCertificates.actions.removeOne(lineItem.giftCertificateId))
  }

  if (newLineItemIds.length === 0) {
    const pageSession: PageSessionEntity = yield call(getCurrentPageSession)
    yield put(
      pageSessions.actions.updateOne({
        id: pageSession.id,
        changes: {
          uploadIds: [],
        },
      })
    )
    yield put(uploads.actions.removeAll(null))
  }

  yield put(
    carts.actions.updateOne({
      id: cart.id,
      changes: {
        lineItemIds: newLineItemIds,
      },
    })
  )

  yield call(
    processAnalyticEvents,
    AnalyticAction({
      eventType: "clearItemFromCart",
      data: { lineItemId },
    })
  )

  yield put(lineItems.actions.removeOne(lineItemId))

  yield put(
    actions.updateOrderSummary({
      reason: "lineItem removed",
      reasonType: "cartChange",
    })
  )
}

export function* watchAddLineItemToCart(): SagaIterator {
  const requestChannel = yield actionChannel(actions.addLineItemToCart.type)
  while (true) {
    const { payload } = yield take(requestChannel)
    yield call(addLineItemToCart, payload)
  }
}
