import { UseToastOptions } from "@chakra-ui/react"
import {
  EntityId,
  FulfillmentTypes,
  OrientationType,
  PrintServiceProductEntityHydrated,
} from "@jackfruit/common"
import { createAction } from "@reduxjs/toolkit"
import { Snapshot } from "recoil"
import { Order } from "~/interfaces/entities/autopilot/Order"
import { UpdateOrderSummaryReasonType } from "./saga/processUpdateOrderSummary"
export interface UploadFilePayload {
  id: EntityId
  file: File
}
const queueImageForProcessing = createAction<UploadFilePayload>(
  "process/queueImageForProcessing"
)

const queueImageForUploading = createAction<UploadFilePayload>(
  "process/queueImageForUploading"
)

export interface SearchStorePayload {}
const searchStores = createAction<SearchStorePayload>("process/searchStores")

export interface PlaceOrderPayload {
  successUrl: string
}
const placeOrder = createAction<PlaceOrderPayload>("process/placeOrder")

export interface CreateOrderPayload {
  successUrl: string
}
const createOrder = createAction<CreateOrderPayload>("process/createOrder")

export interface UpdateOrderSummary {
  reason?: string
  reasonType?: UpdateOrderSummaryReasonType
}
const updateOrderSummary = createAction<UpdateOrderSummary>(
  "process/updateOrderSummary"
)

export interface CouponCodePayload {}
const applyCouponCode = createAction<CouponCodePayload>("process/couponCode")

export interface CreateUploadPayload {
  pageId: EntityId
  file: File
  scrollTo?: (to: string) => void
  nextBlockName?: string
}
export const createUpload = createAction<CreateUploadPayload>("uploads/create")

export interface CreateLineItemPayload {
  productId: EntityId
  createFromProduct?: boolean
}
export interface CreateLineItemWithImagePayload extends CreateLineItemPayload {
  file: File
}

const createLineItem = createAction<CreateLineItemPayload>("lineItems/create")
const createLineItemWithImage = createAction<CreateLineItemWithImagePayload>(
  "lineItems/createWithImage"
)

export interface RemoveLineItemPayload {
  lineItemId: EntityId
}
const removeLineItem = createAction<RemoveLineItemPayload>("lineItems/remove")

export interface CreateLineItemFromTemplatePayload
  extends CreateLineItemPayload {
  blockId: EntityId
  productTemplateId: EntityId
  blockTemplateId?: EntityId
}

const createLineItemFromTemplate =
  createAction<CreateLineItemFromTemplatePayload>(
    "lineItems/createFromTemplate"
  )

export interface DuplicateLineItemPayload {
  lineItemId: EntityId
}

const duplicateLineItem = createAction<DuplicateLineItemPayload>(
  "lineItems/duplicate"
)

export interface UpdateLineItemProduct {
  lineItemId: EntityId
  productId: EntityId
  event: "productChanged" | "storeChanged" | "fulfillmentChanged"
}
const updateLineItemProduct = createAction<UpdateLineItemProduct>(
  "lineItems/updateProduct"
)

export interface UpdateLineItemProductTemplate {
  lineItemId: EntityId
  productTemplateId: EntityId
}
const updateLineItemProductTemplate =
  createAction<UpdateLineItemProductTemplate>("lineItems/updateProductTemplate")

export interface UpdateLineItemProductTemplateVariant {
  lineItemId: EntityId
  productTemplateVariantId: string
}
const updateLineItemProductTemplateVariant =
  createAction<UpdateLineItemProductTemplateVariant>(
    "lineItems/updateProductTemplateVariant"
  )

export interface UpdateImageRegionImage {
  imageRegionId: EntityId
  file: File
}
const updateImageRegionImage = createAction<UpdateImageRegionImage>(
  "imageRegions/updateImage"
)

export interface UpdateMissingImagesForLineItem extends DialogPayload {
  lineItemId: EntityId
  files: File[]
}
const updateMissingImagesForLineItem =
  createAction<UpdateMissingImagesForLineItem>(
    "lineItems/updateMissingImagesForLineItem"
  )

export interface SetUpdateMissingImagesForLineItemDialogChoice {
  choice: boolean
}
const setUpdateMissingImagesDialogChoice =
  createAction<SetUpdateMissingImagesForLineItemDialogChoice>(
    "lineItems/setUpdateMissingImagesDialogChoice"
  )

export interface UpdateLineItemOrientationPayload {
  lineItemId: EntityId
  orientation: OrientationType
}
const updateLineItemOrientation =
  createAction<UpdateLineItemOrientationPayload>("lineItems/updateOrientation")

export interface DownloadProductTemplates {
  blockTemplateId: EntityId
  preTags: EntityId[]
  tags?: EntityId[]
  limit?: number
  offset?: number
  keyword?: string
}
const downloadProductTemplates = createAction<DownloadProductTemplates>(
  "productTemplates/download"
)

export interface DownloadProductTemplateTags {
  tags: EntityId[]
}
const downloadProductTemplateTags = createAction<DownloadProductTemplateTags>(
  "productTemplateTags/download"
)

interface DialogPayload {
  onOpen?: () => void
  onClose?: () => void
}

export interface CheckCompatibilityPayload extends DialogPayload {
  availableProducts: PrintServiceProductEntityHydrated[]
}

export interface ProductCompatibilityPayload {
  availableProducts: PrintServiceProductEntityHydrated[]
}

const productCompatibility = createAction<SearchStorePayload>(
  "process/productCompatibility"
)

export interface ProductCompatibilityDialogPayload {
  choice: boolean
}
const productCompatibilityDialog =
  createAction<ProductCompatibilityDialogPayload>(
    "productCompatibility/dialogChoice"
  )

export interface UpdateFulfillementPayload {
  pageId: EntityId
  fulfillment: FulfillmentTypes
  onOpen?: ({
    lineItemsToKeep,
    lineItemsToRemove,
  }: {
    lineItemsToKeep: EntityId[]
    lineItemsToRemove: EntityId[]
  }) => void
  onClose?: () => void
}
const updateFulfillment = createAction<UpdateFulfillementPayload>(
  "process/updateFulfillment"
)
const updatedFulfillmentForPage = (pageId: EntityId) =>
  createAction(`process/updatesFulfillment_${pageId}`)

export interface UpdateStorePayload {
  storeId: EntityId
  onOpen?: ({
    lineItemsToKeep,
    lineItemsToRemove,
  }: {
    lineItemsToKeep: EntityId[]
    lineItemsToRemove: EntityId[]
  }) => void
  onClose?: () => void
}
const updateStore = createAction<UpdateStorePayload>("process/updateStore")
const updatedStoreForPage = (pageId: EntityId) =>
  createAction(`process/updatedStoreForPage_${pageId}`)

export interface ApplyEditorChangesPayload {
  lineItemId: EntityId
  snapshot: Snapshot
}

const applyEditorChanges = createAction<ApplyEditorChangesPayload>(
  "process/applyEditorChanges"
)

interface addLineItemToCartPayload {
  lineItemId: EntityId
  cartId: EntityId
}

export const addLineItemToCart = createAction<addLineItemToCartPayload>(
  "process/addLineItemToCart"
)
const restoreCheckoutData = createAction("process/restoreCheckoutData")
const restoreCartData = createAction("process/restoreCartData")

export interface BootstrapPagePayload {
  id: EntityId
  toast: (options?: UseToastOptions) => void
}

const bootstrapPage = createAction<BootstrapPagePayload>(
  "process/bootstrapPage"
)

export interface PlaceReOrderPayload {
  order: Order
  onOpen: ({
    canReOrderSomeProducts,
    lineItemLabels,
  }: {
    canReOrderSomeProducts: boolean
    lineItemLabels: string[]
  }) => void
  onClose: () => void
}

const placeReOrder = createAction<PlaceReOrderPayload>("process/placeReOrder")

export interface OrderFromStorePayload {
  storePlace: string
  storeId: EntityId
  storeLat: number
  storeLng: number
}

export const orderFromStore = createAction<OrderFromStorePayload>(
  "process/orderFromStore"
)

export interface UploadUriImagesPayload {
  imageURIs: string[]
  pageId: EntityId
  toast: (options?: UseToastOptions) => void
  scrollTo?: (to: string) => void
  nextBlockName?: string
}

export const uploadUriImages = createAction<UploadUriImagesPayload>(
  "process/uploadUriImages"
)

export const actions = {
  queueImageForUploading,
  queueImageForProcessing,
  searchStores,
  updateOrderSummary,
  placeOrder,
  createOrder,
  applyCouponCode,
  createUpload,
  createLineItem,
  removeLineItem,
  createLineItemWithImage,
  createLineItemFromTemplate,
  duplicateLineItem,
  updateLineItemProduct,
  updateLineItemProductTemplate,
  updateLineItemProductTemplateVariant,
  updateImageRegionImage,
  updateMissingImagesForLineItem,
  setUpdateMissingImagesDialogChoice,
  updateFulfillment,
  updatedFulfillmentForPage,
  updateStore,
  updatedStoreForPage,
  updateLineItemOrientation,
  uploadUriImages,
  downloadProductTemplateTags,
  downloadProductTemplates,
  productCompatibility,
  productCompatibilityDialog,
  applyEditorChanges,
  addLineItemToCart,
  restoreCheckoutData,
  restoreCartData,
  bootstrapPage,
  placeReOrder,
  orderFromStore,
}
