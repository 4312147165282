import { useToast } from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { setCurrentPageId } from "~/redux/context"
import { actions } from "~/redux/process"
import { application } from "~/redux/state/application"
import { usePage } from "./usePage"
import { usePageSession } from "./usePageSession"

export const useBootstrapPage = (pageId: EntityId) => {
  setCurrentPageId(pageId)

  const dispatch = useDispatch()

  const { page } = usePage(pageId)
  const { pageSession } = usePageSession(page.id)
  const toast = useToast()

  useEffect(() => {
    window.appVersion = process.env.GATSBY_APP_VERSION ?? "0.0.0"
    window.debugJson = {}
    dispatch(application.actions.setCurrentPageId(pageId))
    if (!pageSession.isReady) {
      dispatch(actions.bootstrapPage({ id: pageId, toast }))
    }
  }, [dispatch, pageId, pageSession.isReady, toast])
}
